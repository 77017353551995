
define('ldsh!components/menu/list/breadcrumb', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '    ';
 for (var i = 0; i < models.length; i++) {

        var m = models[i];
        var name = m.get('name');
        var url = m.get('url');
        var active = '';

        if(i === models.length - 1){
            active = 'active';
        }
    ;
__p += '\n\n    <li class="link ' +
((__t = ( active )) == null ? '' : __t) +
'" data-url="' +
((__t = ( url )) == null ? '' : __t) +
'">\n         <a href="' +
((__t = ( url )) == null ? '' : __t) +
'">' +
((__t = ( name )) == null ? '' : __t) +
'</a>\n    </li>\n        ';
 if(i !== models.length - 1 ){ ;
__p += '\n            <li class="trenner">></li>\n        ';
 }; ;
__p += '\n    ';
 }; ;
__p += '\n';

}
return __p
};});
