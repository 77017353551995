
define('components/isotope/list/view',['require','exports','module','app','jquery.isotope','../item/view','ldsh!./template'],function(require, exports, module) {
	'use strict';

	//-----------------------------------------------------------------------------------------------------
	//CLASS IMPORTS
	//-----------------------------------------------------------------------------------------------------

	var app = require('app');
	var Isoptope = require('jquery.isotope');
	var Item = require('../item/view');

	//-----------------------------------------------------------------------------------------------------
	//CLASS VARS
	//-----------------------------------------------------------------------------------------------------

	var moduleName = 'Isotope';
	var initialized = false;

	var options = app.options;

	var maxWidth = app.maxWidth;
	var minWidth = app.minWidth;

	var duration = options.duration;

	//-----------------------------------------------------------------------------------------------------
	//CLASS
	//-----------------------------------------------------------------------------------------------------

	var Layout = Backbone.View.extend({
		//manage:true,
		module: moduleName + ' List',
		template: require('ldsh!./template'),
		//id: '#main',

		initialize: function() {

			this.currentFilter = '';

			this.listenTo(this.collection, 'reset', this.render);

			app.pubsub.on('change:tiles', function(currentCollection) {

				console.log('%c--- pubsub: change:tiles ---', 'color:white; background-color:green');

				this.renderIsotope(currentCollection);

			}, this);

			app.pubsub.on('change:resize', function() {

				//console.log('%c--- pubsub: change:resize ---', 'color:white; background-color:green');


				if(!initialized){return;}

				// SORT
				this.changeSort();

				// POSITION
				this.resizeLayout();

			}, this);

			app.pubsub.on('filter:tiles', function(filter, open, el) {

				console.log('%c--- pubsub: filter:tiles ---', 'color:white; background-color:green');

				this.filter(filter, false, open, el);

				//console.log('::::: :::::: ', filter);

			}, this);

		},

		events: {
			//'submit form': 'updateOrg'
		},

		serialize: function() {

			return {
				images: app.images
			};
		},

		beforeRender: function() {

			this.renderList();

		},

		afterRender: function() {

			//$('.preloading').fadeOut();

			if (this.collection.length === 0 || initialized) {
				return;
			}

			initialized = true;

			//$preloader = $('#preloader');

			this.initIsotope();
			this.changeSort();
			this.resizeLayout();

		},

		renderList: function() {

			this.removeItems();
			this.renderItems();
		},

		renderItems: function() {

			this.collection.each(function(item, i) {

				this.insertView('#tiles', new Item({
					model: item,
					count:i
				}));
			}, this);

		},

		removeItems: function() {

			this.getViews('#tiles').each(function(item) {
				if (item) {
					item.remove();
				}
			});

			if(initialized){
				this.$isotope.isotope('flush');
				//this.$isotope.isotope( 'remove', $items );
			}
		},

		renderIsotope: function(currentCollection) {


			if(initialized){
				this.$isotope.isotope('flush');
				//this.$isotope.isotope( 'remove', $items );
			}

			this.collection.reset(currentCollection.tiles);


			if(currentCollection.tiles === false){
				return;
			}

			var $element = this.$isotope.children();


			/*this.$isotope.isotope( 'addItems', $element, function(){
				console.log('addItems');
				this.$isotope.isotope('reLayout');
			});*/
			/*this.$isotope.isotope( 'layout', $element, function(){
				console.log('layout');
				this.$isotope.isotope('reLayout');
			});*/


			this.$isotope.isotope('insert', $element);



			this.setFilter(currentCollection);
			//this.$isotope.isotope('reLayout');
			//this.$isotope.isotope({ filter: 'test' });

			/*if(app.phone){

				this.$isotope.find('.element').css({
					'margin-left': 0,
					'margin-right': 0
				});
			}*/
		},

		initIsotope: function() {
			var focus = this;

			// Adds a .isotope( 'flush' ) method to your Isotope widget.
			// This method will remove all items from the widget and from the DOM,
			// then trigger a reLayout.
			$.Isotope.prototype.flush = function() {
				this.$allAtoms = $();
				this.$filteredAtoms = $();
				this.elemCount = 0;
				//this.element.children().hide();
				this.reLayout();
			};


			this.$isotope = $('#isotope-container');


			/*if(app.phone){

				this.$isotope.find('.element').css({
					'margin-left': 0,
					'margin-right': 0
				});
			}*/

			if(!app.phone){

				this.$isotope
					.css({
						'max-width': maxWidth,
						'min-width': minWidth
					});

			}else{

				options.transformsEnabled = false;
				options.animationEngine = 'none';
				options.isFilterAnimate = false;

				this.$isotope.addClass('no-transition');
			}

			/*this.$isotope
				.children('.isotope-item').css({
					'-webkit-transition-duration': duration.toString() + 's',
					'-moz-transition-duration': duration.toString() + 's',
					'transition-duration': duration.toString() + 's'
				});*/


			this.$isotope.isotope({

				transformsEnabled: options.transformsEnabled,
				layoutMode: options.layoutMode,
				animationEngine : options.animationEngine,
				animationOptions: {
					duration: options.duration,
					easing: 'linear',
					queue: false
				},
				containerStyle: {
					position: 'relative',
					overflow: 'hidden'
				},
				itemSelector: '.element',
				// disable window resizing
				resizable: false,
				//sortBy : 'category',
				//sortAscending : true,
				masonry: {
					columnWidth: options.colWidth + options.colPadding
					/*,
					gutterWidth: 15*/
				},
				cellsByRow: {
					columnWidth: options.colWidth + options.colPadding,
					rowHeight: options.colHeight + options.colPadding
				},
				getSortData: {

					cat3: function($elem) {


						if(app.currentCollection.sortable){
							var isSort = $elem.hasClass('sort');
							//return (isSort?' ':'') + $elem.find('.sort').toLowerCase();
							return $elem.find('.sort').text().toLowerCase();
						}else{
							return $elem.attr('data-cat3');
						}

					},
					cat2: function($elem) {

						if(app.currentCollection.sortable){
							var isSort = $elem.hasClass('sort');
							//return (isSort?' ':'') + $elem.find('.sort').text().toLowerCase();
							return $elem.find('.sort').text().toLowerCase();
						}else{
							return $elem.attr('data-cat2');
						}

					},

					cat1: function($elem) {
						return $elem.attr('data-cat2');
					}
					/*,
					name: function($elem) {
						// get text of .weight element
						var name = $elem.find('h2').text();
						// replace parens (), and parse as float
						return parseFloat( name.replace( /[\(\)]/g, '') );
					}*/
				},
				onLayout: function($elems, instance) {

					//$preloader.removeClass('rotating').hide();
					/*console.log('layout', instance);
					console.log($elems);*/

				}
			});
		},

		setFilter: function(currentCollection){

			var focus = this;

			if(this.timerFilter){
				clearTimeout(this.timerFilter);
			}

			//this.$isotope.isotope({ filter: '*',  isAnimate: false });

			if(currentCollection.urlType){

				this.filters = [];
				_.each( currentCollection.tiles, function( val, key ) {

					if ( val['url_type'] === 'filter' ) {
						focus.filters.push( val['url']);
					}
				});

				//this.filterList = _.toArray(this.filters).join(',');

				this.timerFilter = _.delay(function() {

					focus.filter('*', true);

				}, 300, '');
			}

		},

		filter: function(filter, init, open, el) {

			//if(!currentCollection.urlType){return;}
			//return false;

			var filterList = _.toArray(this.filters).join(',');

			if(filter !== '*' && this.currentFilter !== filter && open){

				this.currentFilter = filter;
				filterList = _.toArray(_.without(this.filters, filter)).join(',');
				var $elTo =  $(el).nextAll(filter).first();

				if(app.phone && !init && $elTo.length !== 0){

					var headerOffset = 105;
					var item_height = 310;

					var prevFilter = $(el).prevAll('.filter').length;
					var $elNext =  $(el).next();

					var count = prevFilter + 2;

					if($elNext.hasClass('filter') || $elNext.index() === 1){
						count = count + 1;
					}

					var className = filter;//'.' + $elTo[0].className;
					console.log('cunt:', count);
					app.utils.scrollToElement(className, 500, (count * item_height) - headerOffset);
				}

			}else{

				this.currentFilter = '';
			}

			var classes = ':not(' + filterList + ')';

			console.log(':::::: :..:: ', app.triggerFromHistory);

			if(init && app.triggerFromHistory){

				if(app.filters[app.urlsegments.currentMenu]){
					var f = app.filters[app.urlsegments.currentMenu];

					filterList = _.toArray(_.without(this.filters, f)).join(',');
					classes = ':not(' + filterList + ')';
				}

			}

			if(filterList.length === 0){
				classes = '*';
			}

			this.$isotope.isotope({ filter: classes,  isAnimate: options.isFilterAnimate });

		},

		changeSort: function() {

			var cat = '';

			if (app.currentColumns === 1) {

				cat = 'cat1';
			}

			if (app.currentColumns === 2) {
				cat = 'cat2';
			}

			if (app.currentColumns === 3) {

				cat = 'cat3';

			}

			if (app.currentColumns === 4) {

				cat = 'original-order';

			}

			console.log(cat);

			var multisort = [ cat, 'name' ];

			this.$isotope.isotope({
				sortBy: cat
			});

			if(!app.phone && app.currentCat !== cat){
				app.pubsub.trigger('isotpe:relayout', cat);
			}


		},

		resizeLayout: function() {

			this.$isotope.width(app.currentWidth)
				.isotope('reLayout');

			//toogleMinVisibility(app.currentColumns);

		},
		cleanup: function() {

			console.log('cleanup', moduleName);

			this.$isotope.isotope('destroy');
			this.$el.off();
		}

	});

	module.exports = Layout;
});
