
define('ldsh!components/footer/list/template', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="footer-container">\n    <div class="text-box">\n        <span class="desktop">\n            <span class="iPad">\n                <span id="tel"><a href="tel:+49408996900">Tel: +49(0)40/89 96 90-0</a></span>\n                <span class="text-box-trenner tel">|</span>\n                <span id="fax">Fax: +49(0)40/89 96 90-44</span>\n                <span class="text-box-trenner fax">|</span>\n            </span>\n            <span class="iPad">\n                <span id="mail"><a href="' +
((__t = ( mailCrypt )) == null ? '' : __t) +
'">E-Mail: ' +
((__t = ( mail )) == null ? '' : __t) +
'</a></span>\n                <span class="text-box-trenner mail">|</span>\n            </span>\n        </span>\n        <span class="desktop iPad">\n            <span id="str">Bernhard-Nocht-Str. 99</span>\n            <span class="text-box-trenner plz">|</span>\n            <span id="plz">20359 Hamburg</span>\n        </span>\n        <div class="text-button-container">\n            <div class="text-button">\n            ';
 for (var i = 0; i < models.length; i++) {

                var m = models[i];
                var id = m.get('id');
                var name = m.get('name');
                var url = m.get('url');

            ;
__p += '\n\n            <span class="link" data-id="' +
((__t = ( id )) == null ? '' : __t) +
'" data-url="' +
((__t = ( url )) == null ? '' : __t) +
'"><a href="' +
((__t = ( domain )) == null ? '' : __t) +
'' +
((__t = ( url )) == null ? '' : __t) +
'">' +
((__t = ( name )) == null ? '' : __t) +
'</a></span>\n                ';
 if(i !== models.length - 1 ){ ;
__p += '\n                   <span class="text-box-trenner">|</span>\n                ';
 }; ;
__p += '\n            ';
 }; ;
__p += '\n\n            </div>\n        </div>\n    </div>\n</div>\n<img src="' +
((__t = ( images )) == null ? '' : __t) +
'footer.png" id="footer-bg">\n';

}
return __p
};});
