
define('ldsh!components/isotope/item/template', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p +=
((__t = ( model.get('html') )) == null ? '' : __t) +
'\n';

}
return __p
};});
