
define('ldsh!components/menu/list/menu', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="header-container">\n    <nav id="nav-container">\n        <div id="nav-logo" class="hide" data-url="' +
((__t = ( indexUrl )) == null ? '' : __t) +
'">\n            <a href="' +
((__t = ( domain )) == null ? '' : __t) +
'' +
((__t = ( indexUrl )) == null ? '' : __t) +
'">\n                <img src="' +
((__t = ( images )) == null ? '' : __t) +
'navi_logo.png" width="256px" height="35px">\n            </a>\n        </div>\n        <span class="clear"></span>\n        <div id="nav-menu-container">\n            <div id="nav-button-home" class="hide" data-url="' +
((__t = ( indexUrl )) == null ? '' : __t) +
'">\n                <a href="' +
((__t = ( domain )) == null ? '' : __t) +
'' +
((__t = ( indexUrl )) == null ? '' : __t) +
'">\n                    <img src="' +
((__t = ( images )) == null ? '' : __t) +
'navi_button.png" width="50px" height="50px">\n                </a>\n            </div>\n            <div id="nav-menu">\n                <ul class="clearfix">\n                    ';
 for (var i = 0; i < models.length; i++) {

                        var m = models[i];
                        var name = m.get('name');
                        var url = m.get('url');
                        var sub = m.get('sub');
                        var hasSub = false;
                        var popup = '';
                        if(sub.length > 0) {
                            hasSub = true;
                            popup = 'aria-haspopup="true"';
                        }
                    ;
__p += '\n\n                    <li class="nav-main" data-name="' +
((__t = ( name )) == null ? '' : __t) +
'" data-url="' +
((__t = ( url )) == null ? '' : __t) +
'">\n                        <a href="' +
((__t = ( domain )) == null ? '' : __t) +
'' +
((__t = ( url )) == null ? '' : __t) +
'"  ' +
((__t = ( popup )) == null ? '' : __t) +
'>' +
((__t = ( name )) == null ? '' : __t) +
'</a>\n\n                        ';
 if(!phone) { ;
__p += '\n                            <span class="space">&nbsp;</span>\n\n                            ';
 if(hasSub) { ;
__p += '\n                                <ul class="nav-sub hide">\n                                    ';
 for (var z = 0; z < sub.length; z++) {
                                        var m = sub[z];
                                        var name = m['name'];
                                        var url = m['url'];
                                    ;
__p += '\n                                        <li data-url="' +
((__t = ( url )) == null ? '' : __t) +
'">\n                                             <a href="' +
((__t = ( domain )) == null ? '' : __t) +
'' +
((__t = ( url )) == null ? '' : __t) +
'">' +
((__t = ( name )) == null ? '' : __t) +
'</a>\n                                        </li>\n                                    ';
 }; ;
__p += '\n                                </ul>\n                            ';
 }; ;
__p += '\n                        ';
 }; ;
__p += '\n                    </li>\n                    ';
 }; ;
__p += '\n                </ul>\n            </div>\n        </div>\n        <div id="nav-breadcrumb"></div>\n    </nav>\n</div>\n<nav id="nav-sub-phone">\n    ';
 if(phone){
        for (var i = 0; i < models.length; i++) {

            var m = models[i];
            var name = m.get('name');
            var url = m.get('url');
            var sub = m.get('sub');
            var hasSub = false;

            if(sub.length > 0) {
                hasSub = true;
            }

            if(hasSub) { ;
__p += '\n            <ul class="nav-sub hide" data-name="' +
((__t = ( name )) == null ? '' : __t) +
'">\n                ';
 for (var z = 0; z < sub.length; z++) {
                    var m = sub[z];
                    var name = m['name'];
                    var url = m['url'];
                ;
__p += '\n                    <li data-url="' +
((__t = ( url )) == null ? '' : __t) +
'">\n                         <a href="' +
((__t = ( domain )) == null ? '' : __t) +
'' +
((__t = ( url )) == null ? '' : __t) +
'">' +
((__t = ( name )) == null ? '' : __t) +
'</a>\n                    </li>\n                ';
 }; ;
__p += '\n            </ul>\n            ';
 }; ;
__p += '\n        ';
 }; ;
__p += '\n    ';
 }; ;
__p += '\n</nav>\n\n';

}
return __p
};});
