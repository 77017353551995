
/**
 * adevLo - light
 * A preloader which just preload images with a callback for each images a final callback
 * http://www.adevby.me
 *
 * Copyright 2011 Maxime Sarri / adevby.me
 */

(function($){
	$.fn.adevLo = function(options){

		var $this = this;

		// Paramètres par défaut
		var defaults = {
			datas : 'none'
		};

		// On charge les nouveaux paramètres
		var options = $.extend(defaults, options);

		// Création du contenu

		if (typeof(options.datas) == 'string'){
			var datasLength = 1;
			preloadImage(options.datas, false);
		}
		else{
			var datasLength = options.datas.length;
			preloadImage(options.datas[0], true,datasLength);
		}

		/////////////////////////////////////////////////
		// On précharge les images
		var i = 1;
		var currentWidth = 0;
		var dims = [];
		var src = '';
		var response = [];

		function preloadImage(url, array, datasLength){
			var img = new Image();
			img.src = url;

			img.onload = function(){
				dims = {height:img.height,width:img.width};
				src = img.src;

				if (!array){
					if (typeof options.success == 'function') {
						options.success.call(this, dims);
					}
				}
				else {
					var response = [];

					response[0] = img.src;
					response[1] = dims;

					if (typeof options.each == 'function') {
						options.each.call(this, response);
					}

					if (i < datasLength){
						preloadImage(options.datas[i], true, datasLength);
						i++;
					}
					else {
						if (typeof options.success == 'function') {
							options.success.call(this);
						}
					}
				}
			};
		}

	};
})(jQuery);
define("jquery.adevlo.light", function(){});
