
define('components/isotope/item/view',['require','exports','module','app','backbone.statefulEvents','jquery.royalslider','jquery.adevlo.light','gmaps','videojs','audiojs','ldsh!./template'],function(require, exports, module) {

	'use strict';

	//-----------------------------------------------------------------------------------------------------
	//CLASS IMPORTS
	//-----------------------------------------------------------------------------------------------------

	var app = require('app');
	var StatefulEvents = require('backbone.statefulEvents');

	var RoyalSlider = require('jquery.royalslider');
	var Adevlo = require('jquery.adevlo.light');

	var Gmaps = require('gmaps');
	var VideoJs = require('videojs');
	var AudioJs = require('audiojs');
	//-----------------------------------------------------------------------------------------------------
	//CLASS VARS
	//-----------------------------------------------------------------------------------------------------

	var moduleName = 'Isotope';

	//-----------------------------------------------------------------------------------------------------
	//CLASS
	//-----------------------------------------------------------------------------------------------------


	var Layout = Backbone.StatefulEventView.extend({
	//var Layout = Backbone.View.extend({
		//manage: true,
		module: moduleName + ' Item',
		template: require('ldsh!./template'),

		tagName: 'div',

		initialize: function() {

			/*AWESOME: Layoutmanager setupView (manage:true) works in require min*/

			Backbone.Layout.setupView(this);

			app.pubsub.on('isotpe:relayout', function() {

				console.log('%c--- pubsub: isotpe:relayout ---', 'color:white; background-color:green');

				if(!app.phone && (this.banner || this.video)){

					this.checkBannerImage();

				}
			}, this);

		},

		events: {

			'click'					: 'click'
		},

		statefulEvents: {

			'desktop mouseover'		: 'mouseover',
			'desktop mouseout'		: 'mouseout'
		},

		getState: function () {

			return app.appState;
		},

		serialize: function() {
			return {
				images: app.images,
				model: this.model
			};
		},

		beforeRender: function() {

			if(app.desktop){
				this.$el.css({opacity:0});
			}
		},

		afterRender: function() {

			//console.log('afterRender');
			this.setup();

		},

		setup: function() {

			var focus = this;

			this.url = this.model.get('url');
			this.urlType = this.model.get('url_type');
			this.layoutType = this.model.get('layout_type');
			this.layoutClass = this.model.get('main_wrapper_class');
			this.childs = this.model.get('childs');

			console.log('layouttype: ' + this.layoutType + '  ||  urlType: ' + this.urlType);

			if(this.model.get('main_wrapper_class')){
				this.className = this.model.get('main_wrapper_class');
				this.$el.addClass(this.className);
			}


			this.checkUrlType(this.urlType);

			this.checkLayoutType(this.layoutType, false);

			var count = 0;

			if(app.desktop){

				count = this.options.count;

				var delay = count * 40;
				this.timerFadeIn = _.delay(function() {

					focus.$el.transition({ opacity: 1 }, 300, 'ease');

				}, delay, '');
			}

			if(!app.phone){

				this.timerAddClass = _.delay(function() {

					focus.$el.children('div').addClass('effect_scale');

				}, 500, '');
			}


		},

		checkUrlType: function(urlType) {

			switch (urlType) {

			case 'internal':

				this.$el.css({cursor: 'pointer'});

				break;
			case 'external':

				this.$el.css({cursor: 'pointer'});

				break;
			case 'mail':

				this.$el.css({cursor: 'pointer'});

				break;
			case 'filter':

				this.className = 'filter';//this.urlType + '_' + this.url.slice( 1 );
				this.$el.addClass(this.className);

				if(!this.childs){return;}
				this.$el.css({cursor: 'pointer'});

				break;
			}
		},

		checkLayoutType: function(layoutType, video) {

			switch (layoutType.toString()) {

			case 'gmap':

				/*GoogleMapsLoader.done(function(GoogleMaps){
					require(['gmaps'], function() {
						focus.initMap();
					});
				}).fail(function(){

					throw("ERROR: Google maps library failed to load");
				});*/
				this.initMap();

				break;
			case 'caption':

				this.checkKachelImage();
				//this.layer = this.$el.find('.effect_layer');
				this.caption = this.$el.find('.box_kachel_title');
				//this.$el.css({cursor: 'pointer'});

				break;
			case 'image':
				console.log('checkKachelImage');
				this.checkKachelImage();
				//this.layer = this.$el.find('.effect_layer');
				//this.$el.css({cursor: 'pointer'});

				break;
			case 'slider':

				this.initSlider();

				break;
			case 'banner':

				if(!app.phone){

					this.$banner = this.$el.find('.banner');
					//this.cat = app.currentCat;
					this.currentSrc = this.$banner.attr('data-src');
					this.$banner.removeAttr('data-src');
					this.currentBannerSrc = this.$banner.attr('data-src-banner-medium');
					this.$banner.removeAttr('data-src-banner-medium');

					if(!video){
						this.banner = true;
						this.checkBannerImage();
					}

				}

				break;
			case 'video':

				this.$banner = this.$el.find('.video');

				this.currentVideoSrc = this.$banner.attr('data-src-banner-video');
				this.$banner.removeAttr('data-src-banner-video');

				if(!app.phone){


					this.checkLayoutType('banner', true);

				}else{

					this.currentSrc = this.$banner.attr('data-src');
					this.$banner.removeAttr('data-src');
				}

				this.video = true;
				this.initVideo();


				break;
			case 'audio':

				this.initAudio();

				break;
			}
		},

		click: function(e) {

			if(!this.urlType){return;}

			e.preventDefault();

			var page = 'tile';

			switch (this.urlType) {

			case 'internal':

				app.pubsub.trigger('change:url', page, this.url);

				break;
			case 'external':

				window.open(this.url);

				break;
			case 'mail':

				location.href = 'mailto:' + this.url;

				break;
			case 'filter':

				if(!this.childs){return;}
				console.log('this.url:', this.url);
				var open = true;

				if(app.triggerFromHistory === false){
					app.filters[app.urlsegments.currentMenu] = false;
				}
				if(app.filters[app.urlsegments.currentMenu] !== this.url){
					app.filters[app.urlsegments.currentMenu] = this.url;
				}else{

					app.filters[app.urlsegments.currentMenu] = false;
					open = false;
				}

				console.log('this.app.filters[app.urlsegments.currentMenu]:', app.filters[app.urlsegments.currentMenu]);

				app.pubsub.trigger('filter:tiles', this.url, open, this.el);
				break;
			}
		},

		mouseover: function(e) {

			//console.log('mouseover');
			//if(!app.desktop){return;}

			//if(this.layoutType === 'image' || this.layoutType === 'caption'){return;}
			if(this.layer){
				this.layer.stop(true).transition({ opacity: 1 });
			}

			if(this.caption){
				//this.caption.stop(true).transition({ bottom: 0 });
				this.caption.stop().animate({ bottom: 0 }, 200 );
			}

			e.preventDefault();
		},

		mouseout: function(e) {

			//console.log('mouseout');
			//if(!app.desktop){return;}

			//if(this.layoutType === 'image' || this.layoutType === 'caption'){return;}
			if(this.layer){
				this.layer.stop(true).transition({ opacity: 0 });
			}

			if(this.caption){
				//this.caption.stop(true).transition({ bottom: -65 });
				this.caption.stop().animate({ bottom: -65 }, 200 );
			}

			e.preventDefault();
		},

		initSlider: function() {

			var $royalSlider = $('.royalSlider');

			$royalSlider.royalSlider({
				// options go here
				// as an example, enable keyboard arrows nav
				controlNavigation: 'none',

				loop: false,
				imageAlignCenter: false,
				//imageScaleMode: 'fill',
				navigateByClick: true,
				numImagesToPreload: 2,
				arrowsNav: true,
				//arrowsNavAutoHide: true,
				//arrowsNavHideOnTouch: true,
				//keyboardNavEnabled: true,
				fadeinLoadedSlide: true,
				//globalCaption: true,
				//globalCaptionInside: false,
				slidesSpacing: 0,
				imageScalePadding: 0,
				imgWidth: 295,
				imgHeight: 295,
				autoScaleSlider: false
			});

			this.slider = $royalSlider.data('royalSlider');

		},

		initMap: function() {


			var focus = this;

			var mapConfig = {

				zoom: 16,
				id: '#gmap',
				address: '<div id="gmapInfoWindow">Bernhard-Nocht-Str. 99<br>20359 Hamburg</div>',
				lat: 53.547220,
				lng: 9.96405,

				title: 'elbedesigncrew GmbH',
				icon: app.images + 'map_icon.png',
				infoImg: app.images + 'tipbox.gif'
			};


			this.gmap = new GMaps({

				div: mapConfig.id,
				zoom: mapConfig.zoom,
				lat: mapConfig.lat,
				lng: mapConfig.lng,

				panControl: false,
				zoomControl: true,
				scaleControl: false,
				mapTypeControl : false,
				overviewMapControl : false,
				rotateControl : false,
				streetViewControl : true,
				mapTypeId: google.maps.MapTypeId.ROADMAP,

				click: function(e) {
					focus.gmap.setZoom(mapConfig.zoom);
					focus.gmap.setCenter(mapConfig.lat, mapConfig.lng);
				}
			});

			this.marker = this.gmap.addMarker({
				icon: mapConfig.icon,
				draggable: false,
				cursor: 'pointer',
				animation: google.maps.Animation.DROP,
				lat: mapConfig.lat,
				lng: mapConfig.lng,
				title: mapConfig.title,
				infoWindow: {
					content: mapConfig.address
				}/*,
				click: function(e) {
					alert('You clicked in this marker');
				}*/
			});

			//this.gmap = new Gmap();
			//this.gmap.init('gmap', address, markerImg, infoImg, lat, lng, title);

		},

		initAudio: function() {

			//<audio src="/mp3/juicy.mp3" preload="auto" />

			audiojs.events.ready(function() {
				var as = audiojs.createAll({
					imageLocation: app.lib + 'player-graphics.gif',
					swfLocation: app.lib + 'audiojs.swf'
				});
			});

		},

		initVideo: function() {

			videojs.options.flash.swf = app.lib + 'video-js.swf';

			var focus = this;
			var dataSrcSplitted = this.currentSrc.split('|');
			var imgSrc = dataSrcSplitted[app.retina && dataSrcSplitted.length > 1 ? 1 : 0];
			var videoSrc = this.currentVideoSrc; // 'http://video-js.zencoder.com/oceans-clip';

			var videoHtml = '<video></video>';
			var attributes = {
				'id': 'video',
				'class': 'video-js vjs-default-skin',
				'width': '915',//'auto',
				'height': '605',//'auto',
				'poster': imgSrc,//'http://lorempixel.com/915/605/',
				'controls': true,
				//'autoplay': ' ',
				'preload': 'auto',
				'fillContainer': 'true',
				'data-setup': '{"nativeControlsForTouch":true}',
				'webkit-playsinline': '',
				'customControlsOnMobile': true
			};

			if(app.phone){

				attributes.width = 295;
				attributes.height = 295;
			}

			//this.$el.find('.effect_layer').remove();
			this.$el.find('.video').parent().append(videoHtml);

			var id = 'video';
			this.$video = this.$el.find('video');
			this.$video.attr(attributes);
			//this.$video.css('visibility', 'hidden');

			this.videoPlayer = videojs(id);
			this.videoPlayer.volume(0.5);


			//videojs(id, {"preload":"metadata", "controls":true, "customControlsOnMobile": true}, function(){});

			//this.videoPlayer.hide();
			//this.videoPlayer.controls(true);
			//this.videoPlayer.poster(src);
			//this.videoPlayer.posterImage.show();
			//this.videoPlayer.bigPlayButton.show();
			//this.videoPlayer.controlBar.show();

			this.videoPlayer.src([
				{ type: 'video/mp4', src: videoSrc + '.mp4' },
				{ type: 'video/webm', src: videoSrc + '.webm' },
				{ type: 'video/ogg', src: videoSrc + '.ogg' }
			]);

			var autoplay = function(){

				focus.timerAutoplay = _.delay(function() {

					focus.videoPlayer.play();

				}, 1000, '');

			};

			this.videoPlayer.ready(function(){

				if(app.mobile){

					$('.vjs-big-play-button').css('opacity', '0');
				}

				/*if (this.options.autoplay && this.paused()) {
					if( ! navigator.userAgent.match(/Firefox/i) )   this.tag.poster = null; // Chrome Fix. Fixed in Chrome v16.
					this.play();
				}*/

				console.log('videoPlayer ready');
				this.on('ended', function(){

					console.log('videoPlayer ended');
					//this.posterImage.show();
					//this.play();
					this.bigPlayButton.show();
				});
				this.on('play', function(){
					console.log('videoPlayer play');
					this.bigPlayButton.hide();
				});
				this.on('pause', function(){
					console.log('videoPlayer pause');
					this.bigPlayButton.show();
				});
			});

			if(!app.phone){

				this.checkBannerImage();
			}

		},

		checkKachelImage: function() {

			var focus = this;

			var $img = this.$el.find('.b-lazy');

			if($img.is( 'img' )){

				/*if(!$img.parent().hasClass('loading')){
					$img.parent().addClass('loading');
				}*/

				//app.bLazy.load($img[0]);
				//app.bLazy.revalidate();


				var dataSrc = $img.attr('data-src');
				$img.removeAttr('data-src')
					.attr('src', '');

				var dataSrcSplitted = dataSrc.split('|');
				var src = dataSrcSplitted[app.retina && dataSrcSplitted.length > 1 ? 1 : 0];

				if(!src){

					console.log('MKB Error: kachel img is not defined ');
					return;
				}

				//$img[0].src = src;

				this.loadImage($img, src);
			}
		},

		checkBannerImage: function() {

			var focus = this;


			if(!this.$banner.is( 'img' )){

				console.log('MKB Error: banner class or banner img tag is not defined ');
				return;
			}


			var dataSrc = this.currentSrc;

			console.log('video', this.video);

			if(app.currentCat === 'cat2'){

				dataSrc = this.currentBannerSrc;

				if(this.video){

					this.videoPlayer.pause();

					if(app.desktop){

						this.videoPlayer.hide();

					}else{

						this.$('#video').css('visibility', 'hidden');
					}

					this.$banner.show();
				}

			}else{

				if(this.video){

					if(app.desktop){

						//this.videoPlayer.bigPlayButton.show();
						this.videoPlayer.show();

					}else{

						this.$('#video').css('visibility', 'visible');
					}


					this.$banner.hide();

					return;
				}
			}


			if(!dataSrc){

				console.log('MKB Error: banner data-src is not defined ');
				return;
			}


			if(this.currentDataSrc === dataSrc){

				console.log('banner src no change');
				return;
			}

			console.log('banner src change');

			this.currentDataSrc = dataSrc;

			var dataSrcSplitted = dataSrc.split('|');
			var src = dataSrcSplitted[app.retina && dataSrcSplitted.length > 1 ? 1 : 0];


			if(!src){

				console.log('MKB Error: banner img is not defined ');
				return;
			}

			this.loadImage(this.$banner, src);

		},

		loadImage: function($img, src) {

			var focus = this;

			$img
				.attr('src', '')
				.removeClass('b-loaded');

			if(!$img.parent().hasClass('loading')){
				$img.parent().addClass('loading');
			}
			/*TODO: ----- TODO new Image causes memory leaks on iPhone -----
					http://www.fngtps.com/2010/mobile-safari-image-resource-limit-workaround/

			*/
			if(app.mobile){

				$img.addClass('b-loaded').removeClass('b-lazy').attr('src', src);

			}else{

				var image = new Image();
				image.src = src;// + '?randomString='+Date.now();

				image.onload = function(){

					$img[0].src = image.src;
					$img
						//.attr('src', src)
						.addClass('b-loaded')
						.parent().removeClass('loading');

					image.onload = null;
					image = null;

				};

			}

			/*this.$el.adevLo({
				datas:src,
				each:function(){},
				success:function(){
					//_.delay(function() {

					$img[0].src = src;
					$img
						//.attr('src', src)
						.addClass('b-loaded')
						.parent().removeClass('loading');

					//}, 400, '');

				}
			});*/
		},

		destroy: function() {

			//console.log('destroy', moduleName);
			this.remove();
		},

		cleanup: function() {

			if(this.timerAutoplay){clearTimeout(this.timerAutoplay);}
			if(this.timerFadeIn){clearTimeout(this.timerFadeIn);}
			if(this.timerAddClass){clearTimeout(this.timerAddClass);}
			if(this.gmap){this.gmap.off();}
			if(this.slider){this.slider.destroy();}
			if(this.videoPlayer){
				this.videoPlayer.dispose();
				this.video = undefined;
			}

			var $img = this.$el.find('img');

			//console.log('destroy', moduleName);

			if($img.is( 'img' )){

				console.log('image true');
				$img[0].src = app.blankImage;
				$img.removeAttr('src');
			}
			this.banner = undefined;
			//console.log('cleanup', moduleName);
		}
	});

	module.exports = Layout;
});
