
define('components/isotope/index',['require','exports','module','./collection','./item/view','./list/view'],function(require, exports, module) {
	'use strict';

	module.exports = {
		Collection: require('./collection'),

		Views: {
			Item: require('./item/view'),
			List: require('./list/view')
		}
	};
});