
define('ldsh!components/error/list/template', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += 'ERROR\n';

}
return __p
};});
