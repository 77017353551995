
define('components/footer/list/view',['require','exports','module','app','backbone.statefulEvents','ldsh!./template'],function(require, exports, module) {
	'use strict';

	//-----------------------------------------------------------------------------------------------------
	//CLASS IMPORTS
	//-----------------------------------------------------------------------------------------------------

	var app = require('app');
	var StatefulEvents = require('backbone.statefulEvents');

	//-----------------------------------------------------------------------------------------------------
	//CLASS VARS
	//-----------------------------------------------------------------------------------------------------
	var moduleName = 'Footer';
	var initialized = false;

	//-----------------------------------------------------------------------------------------------------
	//CLASS
	//-----------------------------------------------------------------------------------------------------

	var Layout = Backbone.StatefulEventView.extend({
		//manage:true,
		module: moduleName + ' List',
		template: require('ldsh!./template'),
		/*id: 'footer',
		tagName: 'footer',*/

		initialize: function() {

			Backbone.Layout.setupView(this);

			this.listenTo(this.collection, 'reset', this.render);

			app.pubsub.on('change:resize', function() {

				//console.log('%c--- pubsub: change:resize ---', 'color:white; background-color:green');
				if(!initialized){return;}
				// POSITION
				this.resizeLayout();

			}, this);

			app.pubsub.on('menu:init', function() {

				console.log('%c--- pubsub: menu:init ---', 'color:white; background-color:green');

				this.initMenu();


			}, this);

		},

		statefulEvents: {

			//'mobile click #tel'		: 'clickTel'
		},

		getState: function () {

			return app.appState;
		},

		serialize: function() {

			var mail = 'contact@elbedesigncrew.de';
			var mailCrypt = app.utils.cryptMailto(mail);
			mail = app.utils.getAscii(mail);

			return {
				images: app.images,
				models: this.collection.models,
				mail:mail,
				mailCrypt:mailCrypt,
				lang: app.lang,
				domain: app.domain
			};
		},

		beforeRender: function() {
			this.$el.hide();
		},

		afterRender: function() {

			this.$footer = $('#footer');
			this.$footerContainer = $('#footer-container');
			this.$footerBg = $('#footer-bg');
			this.footerWidth = this.$footerBg.width();

			this.resizeLayout();

			if(app.desktop){
				this.$tel = $('#tel');
				var tel = this.$tel.children('a').text();
				this.$tel.children('a').removeAttr('href');

				this.$tel.text(tel);
			}

			if (this.collection.length === 0) {
				return;
			}

			if(!initialized){

				this.initMenu();
			}

			this.$el.show();

			initialized = true;

		},

		clickTel: function(e) {

			location.href = 'tel:+49408996900';
			e.preventDefault();
		},

		initMenu: function() {


			this.$('.text-button').children('span.link').on('click', function(e) {

				e.preventDefault();

				var $this = $(this);

				var page = 'footer';
				var url = $this.data('url');
				var id = $this.data('id');

				if(id === 'lang'){

					app.pubsub.trigger('change:lang', page, url);

				}else{

					app.pubsub.trigger('change:url', page, url);
				}

			});

		},

		resizeLayout: function() {

			var n = app.maxWidth;

			if (!app.phone) {
				n = app.currentWidth - app.options.colPadding;
			}

			var mR = 0;

			//toogleMinVisibility(app.currentColumns);

			var factor = (1225 - app.options.colPadding) / app.currentWidth;
			var footerHeight = 55 / factor;

			this.$footerBg.css({
				width: n,
				height: footerHeight
			});

			this.$footerContainer.css({
				width: n
			});

		},

		cleanup: function() {

			console.log('cleanup', moduleName);
			this.$('.text-button').children('span.link').off();
			this.$el.off();
		}

	});

	module.exports = Layout;
});
