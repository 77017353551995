
define('ldsh!components/isotope/list/template', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div id="tiles"></div>\n';

}
return __p
};});
