
define('components/footer/index',['require','exports','module','./collection','./list/view'],function(require, exports, module) {
	'use strict';

	module.exports = {
		Collection: require('./collection'),

		Views: {
			List: require('./list/view')
		}
	};
});