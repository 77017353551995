
define('components/menu/list/breadcrumb',['require','exports','module','app','ldsh!./breadcrumb'],function(require, exports, module) {
	'use strict';

	//-----------------------------------------------------------------------------------------------------
	//CLASS IMPORTS
	//-----------------------------------------------------------------------------------------------------

	var app = require('app');

	//-----------------------------------------------------------------------------------------------------
	//CLASS VARS
	//-----------------------------------------------------------------------------------------------------
	var moduleName = 'Breadcrumb';
	var initialized = false;
	//-----------------------------------------------------------------------------------------------------
	//CLASS
	//-----------------------------------------------------------------------------------------------------

	var Layout = Backbone.View.extend({
		//manage:true,
		module: moduleName + ' List',
		template: require('ldsh!./breadcrumb'),
		tagName: 'ul',

		initialize: function() {

			this.listenTo(this.collection, 'reset', function(){

				//this.cleanup();
				this.render();

			});

		},

		serialize: function() {

			return {
				images: app.images,
				models: this.collection.models,
				lang: app.lang
			};
		},

		beforeRender: function() {

		},

		afterRender: function() {

			/*if (this.collection.length === 0 || initialized) {
				return;
			}
			initialized = true;

			;*/

			this.renderMenu();

		},

		renderMenu: function() {

			this.$el.children('li.link').on('click', function(e) {

				var $this = $(this);

				var page = 'breadcrump';
				var url = $this.data('url');

				app.pubsub.trigger('change:url', page, url);

				e.preventDefault();
				//e.stopPropagation();
			});

		},


		destroy: function() {

			//console.log('destroy', moduleName);
			this.remove();
		},

		cleanup: function() {

			console.log('cleanup', moduleName);
			this.$el.children('li.link').off();
			this.$el.off();
		}

	});

	module.exports = Layout;
});