
define('components/menu/list/menu',['require','exports','module','app','./breadcrumb','ldsh!./menu'],function(require, exports, module) {
	'use strict';

	//-----------------------------------------------------------------------------------------------------
	//CLASS IMPORTS
	//-----------------------------------------------------------------------------------------------------

	var app = require('app');
	var Breadcrumb = require('./breadcrumb');

	//-----------------------------------------------------------------------------------------------------
	//CLASS VARS
	//-----------------------------------------------------------------------------------------------------
	var moduleName = 'Menu';
	var initialized = false;
	var curItem = false;
	//-----------------------------------------------------------------------------------------------------
	//CLASS
	//-----------------------------------------------------------------------------------------------------

	var Layout = Backbone.View.extend({
		//manage:true,
		module: moduleName + ' List',
		template: require('ldsh!./menu'),

		//id: 'header-container',
		tagName: 'div',

		initialize: function() {

			this.listenTo(this.collection, 'reset', this.render);

			app.pubsub.on('change:menu', function(menu, catId) {

				console.log('%c--- pubsub: change:menu ---', 'color:white; background-color:green');

				if(app.phone){

					this.changePhoneMenu(menu, catId);

				}else{

					this.changeMenu(menu, catId);
				}


			}, this);

			app.pubsub.on('change:breadcrumb', function(page, url) {

				console.log('%c--- pubsub: change:breadcrumb ---', 'color:white; background-color:green');

				if(this.breadcrumb){
					var col = this.getBreadcrumb(page, url);
					this.breadcrumb.reset(col);
				}



			}, this);

			app.pubsub.on('menu:init', function() {

				console.log('%c--- pubsub: menu:init ---', 'color:white; background-color:green');

				this.initMenu();


			}, this);

			app.pubsub.on('change:resize', function() {

				//console.log('%c--- pubsub: change:resize ---', 'color:white; background-color:green');
				if (!initialized) {
					return;
				}
				// POSITION
				this.resizeLayout();

			}, this);

		},

		serialize: function() {

			return {
				images: app.images,
				models: this.collection.models,
				lang: app.lang,
				domain: app.domain,
				phone: app.phone,
				indexUrl : app.lang + '/' + app.urlsegments.index
				//indexUrl : app.domain + app.lang + '/' + app.urlsegments.index
			};
		},

		beforeRender: function() {

			//console.log('beforeRender', moduleName);
			this.$el.hide();
		},

		afterRender: function() {

			if (this.collection.length === 0) {
			//if (this.collection.length === 0) {
				return;
			}

			this.$nav = $('#nav-menu');
			this.$header = $('#header');
			this.$headerContainer = $('#header-container');
			this.$navContainer = $('#nav-container');
			this.$navLogo = $('#nav-logo');
			this.$navHomeButton = $('#nav-button-home');

			this.$navContainer.css({
				'max-width': app.maxWidth// - app.options.colPadding
			});

			if (!app.phone) {

				this.$headerContainer
					.css({
						'min-width': 786
					});
			}


			if(!initialized){

				this.initMenu();

			}

			this.resizeLayout();

			this.$header.show();

			initialized = true;

			this.$el.show(300);

		},

		initMenu: function() {

			if (app.phone) {

				this.renderPhoneMenu();
			}
			if (app.tablet) {

				this.renderTabletMenu();
			}
			if (app.desktop) {

				this.renderMenu();
			}

			this.renderHeaderMenu();
			this.renderBreadcrumb();

		},

		renderBreadcrumb: function() {

			this.breadcrumb = new Backbone.Collection();

			/*if(app.phone){

				$('#nav-sub-phone').after($('#nav-breadcrumb'));
			}*/

			this.getViews('#nav-breadcrumb').each(function(item) {
				if (item) {
					item.remove();
				}
			});

			this.insertView('#nav-breadcrumb', new Breadcrumb({
					collection: this.breadcrumb
				}));

			this.breadcrumb.reset(this.getBreadcrumb('lang', app.router.current().fragment));
		},

		renderHeaderMenu: function() {

			this.$navHomeButton.removeClass('hide');
			this.$navLogo.removeClass('hide');

			this.$navLogo.on('click', function(e) {

				var $this = $(this);

				var page = 'header';
				var url = $this.data('url');
				app.pubsub.trigger('change:menu', page, url);
				app.pubsub.trigger('change:url', page, url);

				e.preventDefault();
			});

			this.$navHomeButton.on('click', function(e) {

				var $this = $(this);

				var page = 'menu';
				var url = $this.data('url');

				app.pubsub.trigger('change:menu', page, url);
				app.pubsub.trigger('change:url', page, url);

				e.preventDefault();
			});

		},

		renderMenu: function() {

			var $navSub = $('.nav-sub');
			var focus = this;
			$navSub.removeClass('hide');

			this.$nav.on('click', 'li', function(e) {

				e.preventDefault();
				e.stopPropagation();

				var $this = $(this);
				var url = $this.data('url');

				if(app.loading){return;}
				//if(app.loading && !$this.hasClass('nav-main') && app.currentUrl === url ){return;}

				var $parent = $this.parent().parent();

				focus.$nav.find('.active').removeClass('active');

				$this.addClass('active');
				$parent.addClass('active');

				var page = 'menu';

				app.triggerFromHistory = false;
				app.pubsub.trigger('change:url', page, url);


			});

		},

		renderTabletMenu: function() {

			//if (!('ontouchstart' in window) && !navigator.msMaxTouchPoints && !navigator.userAgent.toLowerCase().match(/windows phone os 7/i)) return false;
			var $navSub = $('.nav-sub');
			var $navMain = $('.nav-main');
			var focus = this;

			$navMain.on('touchstart', 'a', function(e) {

				e.preventDefault();
				e.stopPropagation();

				var $this = $(this);
				var $parent = $this.parent();

				var isMain = $parent.hasClass('nav-main');

				if (!isMain) {
					return;
				}

				if ($parent[0] != curItem[0]) {

					curItem = $parent;
					console.log('main click');
					$navSub.addClass('hide');
					$parent.children('.nav-sub').removeClass('hide');

				} else {

					console.log('main dblclick');

					focus.$nav.find('.active').removeClass('active');
					$parent.addClass('active');
					//------------------------------------
					$parent.children('.nav-sub').addClass('hide');
					curItem = false;
					//------------------------------------
					var page = 'menu';
					var url = $parent.data('url');

					app.triggerFromHistory = false;
					app.pubsub.trigger('change:url', page, url);
				}

			});

			$navSub.on('touchstart', 'li', function(e) {

				e.preventDefault();
				e.stopPropagation();

				var $this = $(e.currentTarget);

				if(app.loading){return;}

				var $parent = $this.parent().parent();

				console.log('sub click');

				focus.$nav.find('.active').removeClass('active');
				$this.addClass('active');
				$parent.addClass('active');

				curItem = false;
				$this.parent().addClass('hide');

				var page = 'menu';
				var url = $this.data('url');

				app.pubsub.trigger('change:url', page, url);
			});

			$(document).on('click touchstart MSPointerDown', function(e) {

				var resetItem = true,
					parents = $(e.target).parents();

				for (var i = 0; i < parents.length; i++) {
					if (parents[i] == curItem[0]) {
						resetItem = false;
					}
				}
				if (resetItem) {
					curItem = false;
					$navSub.addClass('hide');
				}
			});

		},

		changeMenu: function(menu, catId) {

			var $navMenu = $('#nav-menu');
			var $navSub = $('.nav-sub');
			var men = false;

			$navMenu.find('.active').removeClass('active');

			$navMenu.find('li').each(function(e) {

				var $this = $(this);
				var $parent = $this.parent().parent();

				var url = $this.data('url');
				var splitUrl = url.split('/');

				if ($this.hasClass('nav-main') && splitUrl[app.urlsegments.menu] === menu && catId === undefined) {

					$navMenu.find('.active').removeClass('active');
					$this.addClass('active');
					men = true;
				}

				if (splitUrl[app.urlsegments.cat] === catId && catId !== undefined) {

					$navMenu.find('.active').removeClass('active');
					$this.addClass('active');
					$parent.addClass('active');
				}

			});

			if(!men && catId === undefined){

				curItem = false;

				if(app.tablet){

					$navSub.addClass('hide');
				}

			}
		},

		renderPhoneMenu: function() {

			var $navSub = $('.nav-sub');
			var $navMain = $('.nav-main');

			//($navSub.addClass('hide');

			$navMain.on('touchstart', 'a', function(e) {

				e.preventDefault();
				e.stopPropagation();

				app.utils.scrollToElement('html', 500);


				var $this = $(this);
				var $parent = $this.parent();
				var name = $parent.data('name');

				var $sub = $('#nav-sub-phone').find("ul[data-name='" + name + "']");

				var isMain = $parent.hasClass('nav-main');
				if (!isMain) {

					return;
				}

				$navMain.removeClass('active');
				$parent.addClass('active');

				if ($parent[0] != curItem[0]) {

					curItem = $parent;
					console.log('main click');

					$navSub.addClass('hide');
					$sub.removeClass('hide');

					if($sub.hasClass('nav-sub')){

						$('#isotope-container').css({
							'margin-top': 7.5
						});

					}

				} else {

					console.log('main dblclick');

					app.utils.scrollToElement('html', 500);

					$navSub.find('.active').removeClass('active');
					$navSub.addClass('hide');

					curItem = false;

					var page = 'menu';
					var url = $parent.data('url');

					app.triggerFromHistory = false;
					app.pubsub.trigger('change:url', page, url);
				}

			});

			$navSub.on('touchstart', 'li', function(e) {

				e.preventDefault();
				e.stopPropagation();

				var $this = $(this);
				var $parent = $this.parent();


				if(app.loading){return;}

				console.log('sub click');

				$navSub.find('.active').removeClass('active');
				$this.addClass('active');
				$parent.addClass('hide');
				var name = $parent.data('name');
				var $main = $(".nav-main[data-name='" + name + "']");
				$main.addClass('active');

				curItem = false;

				var page = 'menu';
				var url = $this.data('url');

				app.pubsub.trigger('change:url', page, url);
			});

		},

		changePhoneMenu: function(menu, catId) {

			//var $navMenu = $('#nav-menu');
			var $navSub = $('#nav-sub-phone').find('li');
			var $navMain = $('.nav-main');

			var men = false;

			$navMain.each(function(e) {

				var $this = $(this);
				var url = $this.data('url');
				var name = $this.data('name');
				var splitUrl = url.split('/');

				if (splitUrl[app.urlsegments.menu] === menu) {

					$navMain.removeClass('active');
					$this.addClass('active');

					men = true;
				}
			});

			if(!men){
				console.log('removeMenu');
				curItem = false;
				$navMain.removeClass('active');

			}

			var submen = false;

			$navSub.each(function(e) {

				var $this = $(this);
				var url = $this.data('url');
				var splitUrl = url.split('/');

				if (splitUrl[app.urlsegments.cat] === catId && catId !== undefined) {

					$navSub.removeClass('active');
					$this.addClass('active');

					submen = true;
				}

			});

			//console.log('changePhoneMenu', submen)
			if(!submen){
				console.log('removeSUBSUBMenu');
				$navSub.removeClass('active');
				$navSub.parent().addClass('hide');

			}
		},

		getBreadcrumb: function(page, urlsegments) {
			//console.log('::::: getBreadcrumb', page, urlsegments)


			var a = urlsegments.split('/');

			//get lang segment
			var lang = a[0];
			//remove lang segment
			a.splice(0, 1);

			var breadcrumb = [];
			var url = lang + '/';
			var segLast;

			// dont show first only seg menu
			/*if(app.phone && a.length === 1){

				//this.cleanup();
				//this.breadcrumb.reset(breadcrumb);
				return breadcrumb;
			}*/

			var i = 0;

			/*if(app.phone){

				i = 1;
				url = lang;
			}*/

			for (i; i < a.length; i++) {

				if (a[i] !== undefined) {

					var obj = {};

					var seg = a[i];
					var name;

					if (i !== 0) {
						url = url + '/';
					}

					url = url + seg;

					//id segment

					if (i === a.length - 1 && _.has(app.collection[segLast], 'details')) {

						if(!_.has(app.collection, segLast)){

							app.pubsub.trigger('error:route', 'breadcrumb id segments');
						}

						//console.log('::::: ', _.has(app.collection[segLast]['details'], seg));
						if(app.collection[segLast]['details'][seg]){

							name = app.collection[segLast]['details'][seg]['name'];

						}else{

							var id_translation = seg;
							var langObj;
							var langKey;

							_.each(app.collection[segLast]['details'], function(obj, key) {

								if(obj.id_translation === id_translation){

									langKey = key;
									langObj = obj;
									return false;
								}

							});

							console.log('::::: ', langKey, langObj);

							// Redirect to url befor id
							var urlRedirect = [];
							urlRedirect = app.router.current().fragment.split('/');
							urlRedirect.pop();

							if(langKey){
								urlRedirect.push(langKey);
							}

							//console.log(urlRedirect);

							// Redirect to Home if no Id
							//urlRedirect.push(app.lang);

							app.router.go(urlRedirect, false, true);
							return false;

						}

					} else {

						if(!_.has(app.collection, seg)){

							app.pubsub.trigger('error:route', 'breadcrumb id segments');
						}

						name = app.collection[seg]['name'];
					}

					obj.url = url;
					obj.name = name;

					breadcrumb.push(obj);
					segLast = seg;
				}
			}

			//this.cleanup();
			//this.breadcrumb.reset(breadcrumb);

			if(app.phone){

				breadcrumb = _.rest(breadcrumb, 2);

			}

			return breadcrumb;
		},

		resizeLayout: function() {

			var n = app.maxWidth;

			if (!app.phone) {
				n = app.currentWidth - app.options.colPadding;
			}
			var mR = 0;


			if (app.currentColumns === 2) {
				n = '100%';
				mR = 30;
			}

			this.$navContainer.css({
				width: n
			});

			this.$navLogo.css({
				marginRight: mR
			});

		},

		cleanup: function() {

			console.log('cleanup', moduleName);

			$(document).off('click touchstart MSPointerDown');
			$('#nav-menu').off();
			$('.nav-main').off();
			$('.nav-sub').off();
			this.$el.off();
		}

	});

	module.exports = Layout;
});