
define('ldsh!templates/main', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<!-- HEADER ================================================== -->\n<header id="header"></header>\n<!-- CONTENT ================================================== -->\n<section id="error-container"></section>\n<section id="isotope-container"></section>\n<!-- FOOTER ================================================== -->\n<footer id="footer"></footer>\n';

}
return __p
};});
