
define('components/error/list/view',['require','exports','module','app','ldsh!./template'],function(require, exports, module) {
	'use strict';

	//-----------------------------------------------------------------------------------------------------
	//CLASS IMPORTS
	//-----------------------------------------------------------------------------------------------------

	var app = require('app');

	//-----------------------------------------------------------------------------------------------------
	//CLASS VARS
	//-----------------------------------------------------------------------------------------------------
	var moduleName = 'Error';
	var initialized = false;

	//-----------------------------------------------------------------------------------------------------
	//CLASS
	//-----------------------------------------------------------------------------------------------------

	var Layout = Backbone.View.extend({
		//manage:true,
		module: moduleName + ' List',
		template: require('ldsh!./template'),
		id: 'error',

		initialize: function() {
			app.pubsub.on('error:view', function(view) {

				console.log('%c--- pubsub: error:view ---', 'color:white; background-color:green');

				if(view){

					this.$el.show();

				}else{

					this.$el.hide();
				}


			}, this);
		},

		serialize: function() {

			return {
				images: app.images,
				lang: app.lang
			};
		},

		beforeRender: function() {

			this.$el.hide();

		},

		afterRender: function() {

		},

		cleanup: function() {

			console.log('cleanup', moduleName);
		}

	});

	module.exports = Layout;
});
