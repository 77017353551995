
define('components/menu/index',['require','exports','module','./collection','./list/menu','./list/breadcrumb'],function(require, exports, module) {
	'use strict';

	module.exports = {
		Collection: require('./collection'),

		Views: {
			Menu: require('./list/menu'),
			Breadcrumb: require('./list/breadcrumb')
		}
	};
});