
define('components/error/index',['require','exports','module','./list/view'],function(require, exports, module) {
	'use strict';

	module.exports = {

		Views: {
			List: require('./list/view')
		}
	};
});